.services {
  padding: 50px 0;

  &__title {
    margin-bottom: 40px;
  }

  &-item {
    height: 100%;
    border-radius: 4px;
    background-size: cover !important;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .3s;

    &__title {
      font-size: getRems(18px);
      line-height: 111%;
    }

    &__btn {
      width: 100%;
      padding: .375rem .75rem;
      // background-color: transparent;
      // border: 2px solid #fff;
      color: $primary;
      transition: .3s;

      [class*='icon'] {
        font-size: getRems(12px);
        transform: rotateZ(-45deg);
      }


      &:hover {
        [class*='icon'] {
          transform: rotateZ(-45deg) scale(1.3);
        }
      }

    }

    &:hover {
      transform: scale(1.1);
    }
  }
}