.modal{
    &-content{
        border: none;
    }
    &-header{
        border: none;
    }
    .btn-close{
        position: relative;
        &::before, &::after{
            content: "";
            width: 2px;
            height: 18px;
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $text-black;
            transform: translate(-50%,-50%) rotate(45deg);
        }
        &::after{
            width: 18px;
            height: 2px;
        }
    }
}