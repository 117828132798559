.product-detail{
    &_gallery{
        padding-top: 50px;
        @include media-breakpoint-down(sm){
            display: flex;
            flex-wrap: wrap;
            padding-top: 0;
        }
    }
    &_thumb{
        transform: rotate(90deg);
        transform-origin: top left;
        width: 350px;
        margin-left: 15%;
        &__slide{
            padding: 0;
            width: 100px;
            height: 100px;
            border-radius: 2px;
            margin-right: 20px;
            &:last-child{
                margin-bottom: 0;
            }
            img{
                transform: rotate(-90deg);
                transform-origin: center;
                height: 100%;
                width: 100%;
            }
        }
        .is-nav-selected{
            border: 2px solid $text-lighten-primary;
        }
        .carousel__nav{
            .carousel__button{
                &.is-prev{
                    left: -40px;
                }
                &.is-next{
                    right: -40px;
                }
            }

        }
        @include media-breakpoint-down(sm){
            transform: none;
            position: relative;
            flex: 0 0 90%;
            order: 13;
            margin-left: 20px;
            margin-top: 20px;
            &__slide{
                img{
                    transform: none;
                }
            }
        }
    }
    &_carousel{
        width: 80%;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-top: -100px;
        &__slide{
            width: 100%;
            padding: 0;
            border-radius: 4px;
            text-align: center;
            height: 500px;

            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }

            @include media-breakpoint-down(md){
                height: 400px;
            }
        }
        @include media-breakpoint-down(sm){
            flex: 0 0 100%;
            width: 100%;
        }
    }

    &_accordion{
        &__item{
            margin-bottom: 40px;
            border: none;
            &:last-child{
                margin-bottom: 0;
            }
        }
        &__header{
            background: #FFFFFF;
            box-shadow: 0px 16px 30px rgba(51, 51, 51, 0.06);
        }
        &__btn{
            border-radius: 4px;
            padding: 8px;
            &:not(.collapsed){
                background: #FFFFFF;
                box-shadow: none;
                color: $text-black;
            }
            &::after{
                background: none;
                content: '+';
                width: fit-content;
            }
            &:not(.collapsed)::after{
                background: none;
                content: '-';
                transform: none;
            }
        }
    }
    &__docs{
        font-weight: 500;
        position: relative;
        padding-left: 25px;
        li{
            margin-bottom: 16px;
            list-style: none;
            &::after{
                position: absolute;
                left: 0;
                @extend [class^="icon-"];
                @extend .icon-document:before;
            }
            a{
                text-decoration: none;
                color: $text-black;
            }
            &:hover{
                color: $text-lighten-primary;
                a{
                    color: $text-lighten-primary;
                }
            }
        }
    }
    &_card{
        &__block{
            position: relative;
            margin-bottom: 24px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        &__compare{
            position: absolute;
            border: 1px solid $separator;
            right: 0;
            top: 0;
            margin: 0;

            input {
                position: absolute;
                width: 0;
                height: 0;
            }

            input:checked + i {
                color: $primary;
            }
        }
        @include media-breakpoint-down(lg){
            margin-top: 20px;
        }
    }
    &_quantity{
        display: table-caption;
        &__field{
            position: relative;
            margin-top: 8px;
            width: fit-content;
        }
        &__input{
            width: 90px;
            padding: 8px;
            border: 1px solid $text-lighten-primary;
            border-radius: 3px;
            font-weight: 500;
            -moz-appearance: textfield;
            &::-webkit-inner-spin-button, &::-webkit-outer-spin-button{
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
            }
            &:focus, &:focus-visible{
                outline: none;
            }
        }
        &__btn{
            font-size: .5rem;
            border: none;
            position: absolute;
            right: 8px;
            height: 10px;
            background-color: transparent;
        }
        &__add{
            top: 7px;
            i{
                transform: rotate(-90deg);
                display: block;
                margin-right: -1px;
            }
        }
        &__less{
            bottom: 8px;
            i{
                display: block;
                transform: rotate(90deg);
            }
        }
    }
    &__btn{
        width: 100%;
        padding: 16px;
        border: none;
        border-radius: 2px;
        color: white;
        font-weight: 500;
        transition: .3s;
        font-size: .9rem;
        background-color: $primary;
        @extend .form-control;
        a{
            text-decoration: none;
            color: white;
        }
        &:hover{
            background-color: $bg-footer;
        }
        &:focus{
            background-color: $bg-footer;
            color: white;
        }
        &_red{
            background-color: #FE696A;
            &:hover{
                background-color: $red;
            }
            &:focus{
                background-color: $red;
                color: white;
                box-shadow: 0 0 0 0.25rem rgba(239, 71, 58, .25);
            }
        }
        &_half{
            width: 55%;
            @include media-breakpoint-down(md){
                width: 100%;
            }
        }
    }
    &_question{
        margin-top: 16px;
        border: 1px solid $text-lighten-primary;
        border-radius: 2px;
        padding: 9px 10px;
        width: 100%;
        height: fit-content;
        justify-content: space-between;
        span{
            color: $text-lighten-primary;
            letter-spacing: 0.01em;
            font-size: getRems(14px);
            font-weight: 500;
        }
        i{
            margin-left: 0;
            margin-right: 6px;
            color: $text-lighten-primary;
            font-size: getRems(14px);
            transform: rotate(-45deg);
        }
        &:hover{
            border-color: $text-lighten-primary;
            span{
                color: #000;
            }
            i{
                color: #000;
                transform: rotate(-45deg) scale(1.3) !important;
            }
        }
    }
    &_prop{
        border: 1px solid $separator;
        border-radius: 2px;
        .card-header{
            padding: 10px;
            background-color: transparent;
            border-color: $separator;
        }
        b{
           font-weight: 500;
        }
        .card-body{
            padding: 10px;
        }
        &__list{
            padding: 0;
            margin-bottom: 0;
            li{
                list-style: none;
                line-height: .9rem;
                margin-bottom: 5px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &_size{
        font-size: .6rem;
        line-height: 108%;

        img{
            margin-right: 30px;
            @include media-breakpoint-down(sm){
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        p{
            margin-bottom: .6rem;
        }
    }
    &__title{
        color: $primary;
    }
}