.catalog-sections {
	.section-item {
		&__poster {
			display: block;
			width: 100%;
			height: 0;
			padding-bottom: 74.77%;
			position: relative;
			margin-bottom: 16px;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}

		&__title {
			display: block;
			font-weight: 500;
			font-size: getRems(18px);
			line-height: 111%;
			text-decoration: none;
			color: $text-black;
			margin-bottom: 16px;

			&:hover {
				color: $primary;
			}
		}

		@include media-breakpoint-only(xs) {
			display: flex;

			&__poster, &__content {
				// flex: 1;
				width: 50%;
			}

			&__poster {
				padding-bottom: 0;
				height: auto;

				img {
					// height: 100;
				}
			}
		}

		&:nth-child(n + 5) {
			margin-top: 40px;
		}

		@include media-breakpoint-down(lg) {
			&:nth-child(n + 3) {
				margin-top: 40px;
			}
		}

		@include media-breakpoint-only(xs) {
			&:nth-child(n + 2) {
				margin-top: 40px;
			}
		}

		// @include media-breakpoint-up(xs) {
		// 	&:nth-child(n + 3) {
		// 		margin-top: 40px;
		// 	}
		// }

	}

	.section-list {

		&__item {
			color: $text-dark;
			margin-bottom: 10px;

			& > a {
				display: flex;
				align-items: center;
				padding-left: 23px;
				color: inherit;
				position: relative;
				font-weight: 500;
				font-size: getRems(14px);
				line-height: 107%;
				letter-spacing: 0.01em;
				text-decoration: none;

				&::before {
					// content: '';
					@extend [class*=" icon-"];
					@extend .icon-chevron:before;

					display: flex;
					justify-content: center;
					align-items: center;
					font-size: getRems(8px);
					position: absolute;
					width: getRems(15px);
					height: getRems(15px);
					border: 1.4px solid $text-dark;
					border-radius: 50%;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}

				&:hover {
					color: $primary;
				}
			}

			& > span {
				font-weight: 500;
				font-size: getRems(14px);
				line-height: 107%;
				letter-spacing: 0.01em;
			}
		}
	}
}