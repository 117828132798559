.projects-filter{
    &__list{
        padding-left: 0;
        display:flex;
        justify-content: space-between;
        padding-top: 5px;
        margin-top: -1px;
        li{
            list-style: none;
            display: inline-block;
            margin-bottom: 10px;
            white-space: nowrap;
            a{
                color: $separator;
                padding: 4px 8px;
                border-radius: 3px;
                &.active{
                    background: rgba(204, 209, 219, 0.2);
                }
                &:hover{
                    color: white;
                }
            }
        }
        @include media-breakpoint-down(md){
            overflow-x: auto;
            flex-wrap: nowrap;
            li{
                margin-right: 16px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}
.projects-section{
    margin-top: 50px;
    @include media-breakpoint-down(md){
        margin-top: 30px;
    }
}
.project-item{
    &__title{
        font-weight: 500;
        font-size: .9rem;
        line-height: 111%;
        letter-spacing: 0.01em;
    }
}
.project-carousel{
    &__slide{
        padding-bottom: 100%;
        img{
            height: 100%;
            object-fit: cover;
            position: absolute;
            border-radius: 2px;
        }
    }
    &__btn{
        background: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        font-size: .65rem;
        height: 48px;
        width: 48px;
        top: 50%;
        transform: translateY(-50%);
        &_prev{
            left: 20px;
        }
        &_next{
            right: 20px;
        }
    }
}