.posts-grid{
    &_card{
        height: 100%;
        border: none;
        img{
            height: 100%;
            object-fit: cover;
            z-index: 1;
            position: absolute;
        }
    }
    &_overlay{
        background: linear-gradient(0deg, rgba(43, 52, 69, 0.6), rgba(43, 52, 69, 0.6));
        z-index: 2;
        padding: 20px;
        border-radius: 4px;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &_text{
        line-height: .9rem;
    }
}