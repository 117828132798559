.reflect-x {
    transform: scaleX(-1);
}
.reflect-y {
    transform: scaleY(-1);
}

html {
    font-size: 20px;
    scroll-behavior: smooth;
}

a {
    transition: .3s;
}

.navbar-light .navbar-toggler {
    color: $body-color;
}

.section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    &-link {
        color: inherit;
        transition: .3s;
        text-decoration: none;

        [class*="icon"] {
            font-size: getRems(21px);
            margin-left: 20px;
        }
    }

    &-sublink {
        font-weight: 500;
        font-size: getRems(16px);
        line-height: 112%;
        text-decoration: none;
    }
    @include media-breakpoint-down(md){
        &-link{
          width: 100%;
          display: flex;
          justify-content: space-between;
          span{
            width: 60%;
          }
        }
      }
}

.btn-arrow {
    [class*="icon"] {
        margin-left: getRems(18px);
        transition: .3s;
    }

    &:hover {
        [class*="icon"] {
            transform: scale(1.3);
        }
    }
}

.mainpage section {
    padding: 50px 0;
    @include media-breakpoint-down(md){
        padding: 30px 0;
    }
}

.btn-arrow {
    height: 68px;
    padding: 20px 40px;
    background-color: #fff;
    line-height: 111%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    border-radius: 2px;
    color: $text-dark;

    [class*="icon"] {
        font-size: getRems(12px);
        transition: .1s;
    }
}

.swiper.containered {
    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        padding: 0 calc((100vw - #{$container-max-width}) / 2 + 7px);
      }
    }
}

.breadcrumbs {
    &__list {
        margin: 0;
    }

    &__item {
        font-size: getRems(12px);
        line-height: 108%;
        height: 28px;
        display: flex;
        align-items: center;
        & > {
            a {
                color: #fff;
                text-decoration: none;
                transition: .3s;

                &:hover {
                    color: $separator;
                }
            }
        }
        span{
            color: $separator;
        }

        & + & {
            &::before {
                width: 28px;
                text-align: center;
                font-size: getRems(8px);
                @extend [class*=" icon-"];
                @extend .icon-chevron:before;
            }
        }
    }
}

.page-title {
    padding: 10px 0 20px 0;
    margin-bottom: 50px;

    &__title {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: getRems(28px);
        line-height: 107%;
        letter-spacing: -0.01em;
        margin-bottom: 0;
        margin-top: 10px;
    }
    &_mod{
        @include media-breakpoint-down('md'){
            background-color: transparent !important;
            color: $text-black !important;
            margin-bottom: 10px;
        }
    }

}

hr{
    background-color: #dee2e6;
    opacity: 1;
}
.post-subtitle{
    font-weight: 500;
    font-size: .9rem;
    line-height: 111%;
    letter-spacing: 0.01em;
    margin-bottom: 10px;
}

/*forms*/
.form-floating{
    margin-bottom: 10px;
    input{
        border: 1px solid #DEE2E6;
        border-radius: 2px;
        height: 65px;
        font-size: 0.8rem;
        @extend .form-control;
    }
    textarea {
        border-left-width: 1px;
        font-size: 0.8rem;
        resize: none;
        font-size: 0.8rem;
        height: auto;
        @extend .form-control;

        + label {
        height: 1.2rem;
        font-size: .8rem;
        line-height: 1.2rem;
        transform: translateX(1px);
        }

        &:focus {
        + label {
            opacity: 1 !important;
            color: rgba(0, 0, 0, 0.6);
        }
        }

        &:not(:placeholder-shown) {
        + label {
            opacity: 1 !important;
            color: rgba(0, 0, 0, 0.6);
        }
        }
    }
}
.form-submit {
    height: 44px;
    font-size: .8rem;
    line-height: 1.2rem;
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 2px;
}
.form-check{
    font-size: .6rem;
    input{
        border-radius: 2px;
    }
    label{
        margin-top: 2px;
    }
}
form:not(.smartfilter) {
    input[type=submit]{
        height: auto;
        @extend .product-detail__btn;
        @extend .product-detail__btn_red;
    }
}

.bx_catalog-compare-list {
    display: none !important;
}

.fly-sidebar{
    background: white;
    box-shadow: 0px 16px 30px rgba(51, 51, 51, 0.06);
    border-radius: 4px;
    margin-top: -100px;
    padding: 20px;
}

.popup-window .popup-window-buttons .btn.btn-default {
    @extend .btn-primary;
    padding: 10px 20px;
}

.popup-window .popup-window-content p {
    margin: 0;
}

.animate__animated {
    visibility: hidden;
}