@import '../../assets/fonts/icomoon/style';

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_cyrilic_ext_regular.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_cyrilic_regular.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_cyrilic_ext_medium.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_cyrilic_medium.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_cyrilic_ext_bold.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_cyrilic_bold.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_latin_ext_regular.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_latin_regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_latin_ext_medium.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_latin_medium.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_latin_ext_bold.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(assets/fonts/rubik/rubik_latin_bold.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(assets/fonts/manrope/manrope_bold.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}


body {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: getRems(16px);
  line-height: 112%;
}

h1 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: bold;
  font-size: getRems(64px);
  line-height: 109%;
  letter-spacing: -0.01em;
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: getRems(32px);
  line-height: 106%;
  letter-spacing: -0.01em;
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 107%;
  letter-spacing: -0.01em;
}
h6{
  font-style: normal;
  font-weight: 500;
  font-size: getRems(18px);
  line-height: 111%;
  letter-spacing: 0.01em;
}
.btn {
  font-style: normal;
  font-weight: 500;
  font-size: getRems(18px);
  line-height: 111%;
  letter-spacing: 0.01em;
}

.btn-link {
  font-style: normal;
  font-weight: 500;
  font-size: getRems(14px);
  line-height: 107%;
  letter-spacing: 0.01em;
  text-decoration: none;
}

.text-caption {
  font-style: normal;
  font-weight: normal;
  font-size: getRems(12px);
  line-height: 108%;
  color: $click-text;
  margin-bottom: 0;
} 