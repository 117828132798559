.stages {
  padding: 50px 0;

  &__title {
    margin-bottom: 8px;
  }

  &__subtitle {
    margin-bottom: 40px;
  }

  .progress {
    counter-reset: progress-bar;
    background-color: #fff;
    &-bar {
      flex: 0 0 0;
      transition: 1s;
      animation: progress .5s linear forwards;

      &:nth-child(2) {
        animation-delay: .5s;
        background-color: #7988A3;
      }
      &:nth-child(3) {
        animation-delay: 1s;
        background-color: #99A4B8;
      }
      &:nth-child(4) {
        animation-delay: 1.5s;
        background-color: #B3BCC9;
      }
      &:nth-child(5) {
        animation-delay: 2s;
        background-color: $separator;
      }
    }
  }

  &-counter {
    display: flex;
    counter-reset: stages;
    padding: 0;
    margin-top: 16px;
  }

  &-item {
    counter-increment: stages;
    list-style: none;
    flex: 0 0 20%;
    font-weight: 500;
    font-size: getRems(18px);
    line-height: 111%;
    color: $text-dark;
    animation: stages .5s linear forwards;
    opacity: 0;
    
    &::before {
      content: counter(stages);
      display: block;
      font-weight: 500;
      font-size: getRems(32px);
      line-height: 106%;
      letter-spacing: -0.01em;
      color: $text-dark;
      margin-bottom: 5px;
    }

    &:nth-child(2) {
      animation-delay: 0.5s;
    }
    &:nth-child(3) {
      animation-delay: 1s;
    }
    &:nth-child(4) {
      animation-delay: 1.5s;
    }
    &:nth-child(5) {
      animation-delay: 2s;
    }
  }
  @include media-breakpoint-down(md){
    .progress{
      transform: rotate(90deg);
      transform-origin: left;
      margin-left: 2%;
      width: 430px;
    }
    &-counter{
      display: block;
      margin-left: 50px;
      margin-top: 0;
    }
    &-item{
      margin-bottom: 30px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

@keyframes progress {
  to {
    flex: 0 0 20%
  }
}

@keyframes stages {
  to {
    opacity: 1;
  }
}