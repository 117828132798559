.about-category{
	ul {
		padding: 0;

		li{
			display: flex;
			align-items: center;
			list-style: none;
			margin-bottom: 16px;
			@extend .icon-file;

			&::before{
				// position: absolute;
				display: block;
				left: 5px;
				font-size: 1.4rem;
				margin-right: 13px;
				@extend [class*=" icon-"];
			}
		}
	}

	&#{&}_img &__image {
		width: 100%;
		height: auto;
	}
}