.post-detail{
    &_img{
        img{
            width: 100%;
            object-fit: cover;
            border-radius: 2px;
        }
        margin-bottom: 20px;
    }
    &_title{
        color: $text-lighten-primary;
    }
}