.catalog-section{
    .catalog-categories{
        background-color: #fff;
        box-shadow: 0px 16px 30px rgba(51, 51, 51, 0.06);
        border-radius: 4px;
        margin-top: -50px;
        @media screen and (max-width:991px) {
            display: none;
        }
        &_header{
            padding: 16px;
        }
        &_body{
            padding: 10px;
        }
    }
    &_list{
        padding: 40px 0;
    }
    &_item{
        border: 0;
        border-radius: 4px;
        margin-bottom: 40px;
        a{
            text-decoration: none;
        }
        .card-img{
            position: relative;
            img{
                height: 200px;
                object-fit: contain;
                @media screen and (max-width:768px){
                    height: 170px;
                }
                @media screen and (max-width:425px){
                    height: 140px;
                }
            }
        }
        .card-body{
            padding: 0;
            margin-top: 16px;
            font-weight: 500;
            color: $text-black;
            p{
                margin-bottom: 8px;
            }
            &_price{
                font-size: .9rem;
            }
        }
        &:hover{
            .card-img-overlay{
                opacity: 1;
            }
        }
    }
    &_overlay{
        background: linear-gradient(0deg, rgba(43, 52, 69, 0.5), rgba(43, 52, 69, 0.5));
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: 0.3s;
        &__content{
            display: inline-block;
        }
        &__btn{
            background: #FFFFFF;
            border-radius: 3px;
            padding: 13px;
            border: none;
            line-height: 0;
            color: $text-black;
            margin: 0 10px;
            cursor: pointer;

            input {
                position: absolute;
                width: 0;
                height: 0;
                opacity: 0;
            }

            input:checked + i {
                color: $primary;
            }

            &:hover{
                background-color: rgb(237, 237, 237);
            }
        }
        @media screen and (max-width:768px){
            opacity: 1;
            background: transparent;
            justify-content: flex-end;
            align-items: start;
            padding: 0;
            &__content{
                display: grid;
            }
            &__btn{
                padding: 7px;
                background: #f5f6f8;
                margin: 0 0 10px;
                width: fit-content;
            }
        }
    }
}

