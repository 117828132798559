.offcanvas {
	padding: 20px;

	& > div {
		padding: 0;
	}

	& &-header {
		padding-bottom: 30px;
		border-bottom: 1px solid $separator;

		.btn-close {
			position: relative;
			&::before, &::after {
				content: '';
				width: 2px;
				height: 18px;
				position: absolute;
				top: 50%;
				left: 50%;
				background-color: $text-black;
				transform: translate(-50%, -50%) rotate(45deg);
			}

			&::after {
				width: 18px;
				height: 2px;
			}
		}
	}

	&__brand {
		img {
			height: 39px;
		}
	}
}