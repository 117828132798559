.left-menu{
    margin: 30px 0;
    &_list{
        padding-left: 0;
        position: relative;
    }
    li{
        list-style: none;
        margin-bottom: 10px;
    }
    &_item{
        a{
            display: block;
            color: $text-black;
            text-decoration: none;
            font-size: .9rem;
            font-weight: 500;
            width: fit-content;
            border-radius: 3px;
            padding: 4px 8px;
            text-align: start;
            &:hover{
                color: $text-black;
                background-color: $bg-dark;
            }
        }

        &__btn{
            background-color: transparent;
            color: $text-black;
            padding: 4px 8px;
            border: none;
            margin-left: 26px;
            &::before{
                @extend [class^="icon-"];
                @extend .icon-chevron:before;
                display: inline-block;
                position: absolute;
                left: 2px;
                font-size: .6rem;
                margin-top: 4px;
                transition: .3s;
                transform: rotate(90deg);
            }
            &:hover, &:active{
                background-color: transparent;
                color: $text-black;
                background-color: $bg-dark;
            }
            &:focus{
                box-shadow: none !important;
                background-color: transparent;
                color: $text-black;
                background: transparent;
            }
            &:focus-visible{
                outline: none;
            }
            &.collapsed{
                &::before{
                    transform: rotate(0deg);
                }
            }
        }
        &__collapse{
            margin-top: 10px;
            ul{
                padding-left: 26px;
                li{
                    a{
                        width: fit-content;
                        font-size: .7rem;
                    }
                }
            }
        }
    }

    .active{
        & > a{
            background-color: $bg-dark;
            color: $text-lighten-primary;
        }

    }
}