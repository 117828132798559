.footer {
	padding: 50px 0 10px 0;
	background-color: $bg-footer;
	color: $text-footer;
	margin-top: 50px;
	@include media-breakpoint-down(sm){
		padding-bottom: 10px;
		padding-top: 30px;
		margin-top: 30px;
	}

	&-brand {
		display: block;
		filter: brightness(0) invert(1);
		margin-bottom: 20px;

		img {
			height: 59px;
		}
	}

	&-phone {
		margin-bottom: 20px;

		&__link {
			font-weight: 500;
			font-size: getRems(18px);
			line-height: 111%;
		}
	}

	&-section-subtitle {
		font-size: getRems(12px);
		line-height: 108%;
		margin-bottom: 0;
	}

	&-socnet {
		&__list {
			margin-top: 8px;
			display: flex;
		}

		&-item {
			& &__link {
				margin-right: 10px;
				display: flex;
				width: 28px;
				height: 28px;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: #ABAEB6;
				color: $bg-footer;
				font-size: 7px;

				.icon-whatsapp {
					font-size: 11px;
				}
				.icon-youtube {
					font-size: 9px;
				}
				.icon-instagram {
					font-size: 10px;
				}

				&:hover {
					color: $primary;
				}
			}
		}
	}

	a {
		color: #ABAEB6;
		text-decoration: none;

		&:hover {
			color: #fff;
		}
	}

	&-section-title {
		font-weight: 500;
		font-size: getRems(18px);
		line-height: 111%;
		margin-bottom: 16px;
	}

	&-menu {
		&__item {
			font-weight: 500;
			font-size: getRems(14px);
			line-height: 107%;
			letter-spacing: 0.01em;
			margin-bottom: 8px;
		}
	}
	&-developer{
		display: flex;
		align-items: center;
		font-weight: 600;
		line-height: initial;
		white-space: nowrap;
		text-align: left;
		width: fit-content;
		float: right;
		font-size: getRems(12px);
		img{
			height: 28px;
			margin-right: 10px;
			filter: invert(.7);
		}
	}
}