.helpfull {
	&-item {
		border: 1px solid rgba(85, 103, 139, 0.2);
		border-radius: 4px;

		&__body {
			display: flex;
			flex-direction: column;
			padding: 28px;
		}

		&__title {
			margin-bottom: 8px;
			letter-spacing: -0.01em;
		}

		&__text {
			line-height: 112%;
			margin: 0;
			margin-bottom: 40px;
		}

		&__btn {
			margin-top: auto;
		}
	}
}