.top-navigation {
  background-color: $bg-dark;
  padding: 10px 0;

  &__item {
    padding: 4px 8px;
    border-radius: 3px;
    font-weight: 500;
    font-size: getRems(14px);
    line-height: 107%;
    letter-spacing: 0.01em;
    color: $text-black;


    &:hover, &.active {
      background-color: $bg-dark;
    }

    &.active {
      color: $primary;
    }

    &_red {
      color: $red;

      &:hover, &.active {
        background-color: $bg-red;
        color: $red;
      }
    }
  }
}