/*-------------HEADER START-------------*/
.header {
  &-infitem {
    display: flex;
    flex-direction: column;
  }

  &-burger {
    height: 28px;
    width: 28px;
    position: relative;
    background-color: transparent;

    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 16px;
      background-color: $text-black;
      transition: .3s;

      &::before, &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        top: -4px;
        left: 0;
        background-color: $text-black;
      }

      &::after {
        top: unset;
        bottom: -4px;
      }
    }

    &:hover &__icon {
      background-color: $primary;

      &::before, &::after {
        background-color: inherit;
      }
    }
  }

  .navbar-brand {
    img {
      height: 52px;
    }
  }

  &-infitem {
    font-size: getRems(12px);
    line-height: 108%;

    &__link {
      color: $text-black;
      text-decoration: none;
    }
  }

  &-btns {
    &__item {
      display: block;
      width: 28px;
      height: 28px;
      text-align: center;
      margin-left: getRems(28px);
      line-height: getRems(28px);
      text-decoration: none;
      color: $text-black;

      &_active{
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          background-color: $red;
          border-radius: 50%;
          top: 0;
          right: 0;
        }
      }
    }
  }

  // #title-search {
  //   position: absolute;
  //   right: 215px;
  //   top: 50%;
  //   transform: translateY(-50%);
  // }

  .navbar-collapse {
    transition: .3s;
    padding-top: 0;
    width: 100%;

    &.show {
      padding-top: 10px;
      // display: flex;
      // justify-content: end;
    }
  }

  #title-search {

    form {
      display: flex;
      width: 100%;
      border-bottom: 1px solid $primary;

      #title-search-input {
        flex: 1;
        width: inherit;
        display: block;
        height: 38px;
        border: none;
        // border-bottom: 1px solid $primary;
        border-radius: 0;
        transition: .3s;
        text-align: center;
        // margin-right: 10px;
        font-size: getRems(30px);
        line-height: 107%;

        &::placeholder {
          text-align: right;
          color: rgb(94, 94, 94);
          font-weight: 400;
          font-size: 1rem;
        }

        &:focus-visible {
          outline: none;
          border-color: $text-lighten-primary;
        }
      }

      button {
        // border-bottom: 1px solid $primary;
        border-radius: 0;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
.sticky-top{
  z-index: 999;
}
/*-------------HEADER END-------------*/
