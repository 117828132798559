.categories {

  &-item {
    text-decoration: none;

    &__poster {
      height: 0;
      position: relative;
      padding-bottom: 66.67%;
      margin-bottom: 8px;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 111%;
      color: $text-dark;
    }
  }
  @include media-breakpoint-down(md){
    .swiper{
      padding-left: 15px;
    }
  }
}