.main-pagination{
    padding-bottom: 5px;

    &_list{
        position: relative;
        margin-bottom: 0;
    }
    &_item{
        margin: 0 5px;
        a{
            color: $text-black;
            border-radius: 3px;
            border: none;
            padding: 8px 12px;
        }
        i{
            font-size: 11px;
            display: inline-block;
        }
        &:first-child, &:last-child{
            position: absolute;
            margin: 0;
            a{
              background-color: $bg-dark;
            }
            @media screen and (max-width: 768px) {
                span{
                    display: none;
                }
            }
        }
        &:first-child{
            left: 0;
            i{
                transform: rotate(180deg);
            }
            span{
                margin-left: 10px;
            }
        }
        &:last-child{
            right: 0;
            span{
                margin-right: 10px;
            }
        }
    }
    &_item.disabled a{
        background-color: $bg-dark !important;
    }
    &_item.active a{
        color: $text-black !important;
        background-color: $bg-red !important;
    }
}