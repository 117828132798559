.cart-section{
    &__list{
        padding-left: 0;
    }
    &__item{
        border-bottom: 1px solid $separator;
        margin-bottom: 20px;
        list-style: none;
        padding-bottom: 20px;
        position: relative;
        a{
            color: $text-black;
            text-decoration: none;
            &:hover{
                color: $primary;
            }
        }
        &:last-child{
            border: none;
        }
        @include media-breakpoint-down(md){
            border: none;
            margin-bottom: 30px;
            padding-bottom: 0;
        }
    }
    &__img{
        height: 170px;
        width: 170px;
        object-fit: cover;
    }
    &__card{
        margin-left: 30px;
    }
    &__product-info{
        p{
            font-weight: 500;
            margin-bottom: 8px;
        }
        @include media-breakpoint-down(sm) {
            padding-right: 16px;
        }
    }
    &__remove{
        position: absolute;
        top: 0;
        right: 0;
        button{
            position: relative;
            height: 14px;
            width: 14px;
            border: none;
            background-color: transparent;
            &::before, &::after{
                opacity: .7;
                content: "";
                width: 2px;
                height: 14px;
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: $red;
                transform: translate(-50%,-50%) rotate(45deg);
            }
            &::after{
                width: 14px;
                height: 2px;
            }
            &:hover{
                &::before, &::after{
                    opacity: 1;
                }
            }
            &:focus-visible, &:focus{
                outline: none;
            }
        }
    }
    @include media-breakpoint-down(lg){
        &__order{
            margin-top: 0;
        }
    }
}