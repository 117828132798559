.company-tabs{
    &_nav{
        position: relative;
        border-bottom: 2px solid #DEE2E6;
        &__link{
            flex: 1;
            border: none;
            padding-bottom: 20px;
            position: relative;
            &:focus{
                box-shadow: none;
            }
            @media screen and (max-width: 425px) {
                font-size: .7rem;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        hr{
            content: '';
            height: 2px;
            width: 0;
            background-color: #FE696A;
            bottom: -2px;
            left: 0;
            position: absolute;
            transition: .3s;
            margin: 0;
        }
    }
    &_content{
        padding-top: 30px;
        ul{
            padding-left: 0;
            li{
                list-style: none;
            }
        }
    }
}
.ymaps-2-1-79-controls-pane, .ymaps-2-1-79-gototech, .ymaps-2-1-79-copyright{
    display: none !important;
}
.sertificate-gallery{
    img{
        width: 100%;
        object-fit: cover;
        cursor: pointer;
    }
}