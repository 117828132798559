.brands {
	&-item {
		display: block;
		height: 100px;
		width: 100%;

		&__image {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		@include media-breakpoint-down(md){
			height: auto;
			margin-bottom: 30px;
		}
	}
}