.catalog-filter{
    margin-top: -1px;
    &_group{
        font-weight: 500;
        font-size: .7rem;
    }
    &_item{
        margin-left: 10px;
        button{
            color: #fff;
            font-size: .7rem;
            border: 2px solid;
            border-radius: 3px;
            padding: 8px;
            &::after{
                @extend [class^="icon-"];
                @extend .icon-chevron:before;
                border: none;
                font-size: .5rem;
                transform: rotate(90deg);
                margin-left: 10px;
                vertical-align: middle;
            }
            &:hover{
                color: #fff;
                background-color: rgba(204,209,219,.2);
            }
        }
        .dropdown-menu {
            border-radius: 3px;
            font-size: .7rem;
            min-width: unset;

            min-width: 300px;
            width: max-content;
            padding: 15px;

            .bx-ui-slider-track-container {
                padding-left: 30px;
                padding-right: 30px;
            }

            .bx-ui-slider-handle {
                &::after {
                    content: '';
                    position: absolute;
                    border: 10px solid transparent;
                    border-bottom-color: $primary;
                    transform: translateY(-50%);
                }

                &.left {
                    margin-left: -10px;
                }

                &.right {
                    margin-right: -10px;
                }
            }

            .bx-ui-slider-range {
                background-color: $primary;
            }

            .bx-filter-parameters-box-container-block {
                flex: 1;
            }

            .bx-filter-parameters-box-container {
                padding: 0;
            }

            .bx-filter-parameters-box-container-block:not(:last-child) {
                margin-right: 30px;
            }
        }
    }

    .catalog-filter_list {
        margin: 0;
    }
}