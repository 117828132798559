.main-slider {
  padding: 0 !important;

  .carousel-caption {
    top: 50%;
    left: 50%;
    right: unset;
    bottom: unset;
    transform: translate(-50%, -50%);
    text-align: left;
  }
  .carousel-item{
    height: 650px;
    img{
      height: 100%;
      object-fit: cover;
    }
  }
  &__title{
    @include media-breakpoint-down(md){
      font-size: 2.4rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 2rem;
    }
  }

  [class*="carousel-control"] {
    width: 48px;
    height: 48px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $text-black;
    opacity: 1;
    border-radius: 50%;

    &:hover {
      opacity: .7;
    }
  }

  .carousel-control-prev {
    left: 50px;
  }
  .carousel-control-next {
    right: 50px;
  }

  &__subtitle {
    margin-top: 20px;
    font-size: getRems(16px);
    line-height: 112%;
  }
  @include media-breakpoint-down(md){
    .carousel-control-prev, .carousel-control-next{
      display: none;
    }
  }
}