@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/icomoon/fonts/icomoon.eot?b2lvw6');
  src:  url('assets/fonts/icomoon/fonts/icomoon.eot?b2lvw6#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon/fonts/icomoon.ttf?b2lvw6') format('truetype'),
    url('assets/fonts/icomoon/fonts/icomoon.woff?b2lvw6') format('woff'),
    url('assets/fonts/icomoon/fonts/icomoon.svg?b2lvw6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-document:before {
  content: "\e90c";
}
.icon-chevron:before {
  content: "\e90b";
}
.icon-youtube:before {
  content: "\e90a";
}
.icon-addtocart:before {
  content: "\e900";
}
.icon-arrow:before {
  content: "\e901";
}
.icon-cart:before {
  content: "\e902";
}
.icon-compare:before {
  content: "\e903";
}
.icon-file:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-profile:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e907";
}
.icon-vk:before {
  content: "\e908";
}
.icon-whatsapp:before {
  content: "\e909";
}
