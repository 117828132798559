.calc-section{
    &__wrap{
        margin-bottom: 40px;
    }
    &__product{
        img{
            margin-bottom: 10px;
        }
        label{
            border: 1px solid white;
            border-radius: 2px;
            padding: 5px;
            cursor: pointer;
            background-color: white;
            &:hover{
                box-shadow: 0px 16px 30px rgba(51, 51, 51, 0.06);
            }
        }
        input[name=product]:checked + label{
            border-color: $primary;
            box-shadow: 0px 16px 30px rgba(51, 51, 51, 0.06);
        }
    }
    &__footer{
        p{
            margin-bottom: 10px;
        }
    }
    &__buttons{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__btn{
        width: auto;
        padding: 25px 40px;
        margin: 0 5px;
        i{
            display: inline-block;
            transform: rotate(90deg);
            font-size: .55rem;
            margin-left: 12px;
        }
        &_red{
            padding-left: 100px;
            padding-right: 100px;
            @media screen and (max-width: 401px) {
                order: -1;
            }

        }
        @include media-breakpoint-down(md){
            padding: 20px 30px;
            font-size: .8rem;
            margin-bottom: 10px;
        }
    }
    &__track{
        width: 240px;
        transform: rotate(-90deg) translateY(-150%);
        position: relative;
        &-wrap{
            position: relative;
        }
        &-input{
            z-index: 9999;
            position: relative;
            &::-webkit-slider-runnable-track{
                height: 2px;
                cursor: pointer;
                background: transparent;
                border: none;
            }
            &::-webkit-slider-thumb {
                height: 15px;
                width: 15px;
                margin-top: -7px;
            }
        }

        &-select{
            position: absolute;
            top: 41%;
            background-color: $primary;
            height: 2px;
        }
        &-points{
            position: absolute;
            top: 31%;
            &-list{
                position: relative;
                width: 240px;
                display: flex;
                justify-content: space-between;
                &>div{
                    position: relative;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background-color: $separator;
                    transition: .3s;
                }
                .bg-point{
                    background-color: $primary;
                }
            }
            
        }
        &-bg{
            position: absolute;
            top: 41%;
            background-color: $separator;
            height: 2px;
            width: 100%;
            z-index: -1;
        }
        &-tickmarks{
            position: absolute;
            transform: translateY(50%) rotate(-90deg);
            height: 229px;
            display: grid;
            width: fit-content;
            text-align: end;
            bottom: 2%;
            right: 38%;
            li{
                list-style: none;
                font-weight: 500;
                font-size: .7rem;
                color: $body-color;
                position: relative;
                padding: 0;
                transform: rotate(180deg);
                color: $click-text;
                cursor: pointer;
                span{
                    position: absolute;
                    right: 0;
                    white-space: nowrap;
                    transition: .3s;
                }
            }
            .tickmarks-li_black{
                color: $text-black;
            }
        }
    }
    &__card{
        img{
            width: 100%;
            height: 255px;
            object-fit: contain;
            @include media-breakpoint-down(md){
                width: 50%;
            }
        }
        h6{
            margin-top: 10px;
        }
        @include media-breakpoint-down(md){
            text-align: center;
            .product-detail_quantity__field{
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &__dropdown{
        button{
            &::after{
                border: none;
                font-size: .5rem;
                transform: rotate(90deg);
                @extend [class*=" icon-"];
                @extend .icon-chevron:before;

            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    &__prices{
        .text-caption{
            color: $text-dark;
        }
    }
    &__step, &__note{
        display: none;
    }
}
#stepBack{
    background-color: $click-text;
    i{
        transform: rotate(-90deg);
    }
    &:hover{
        background-color: $text-footer;
    }
}